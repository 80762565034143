<template>
  <Particles
    id="tsparticles"
    :particlesInit="particlesInit"
    :particlesLoaded="particlesLoaded"
    :options="{
      background: {
        color: {
            value: '#0A1148'
        }
      },
      detectRetina: true,
      fpsLimit: 60,
      particles: {
        color: {
          value: '#222A63'
        },
        opacity: {
          value: 0.5
        },
        shape: {
          type: 'circle'
        },
        links: {
          color: '#222A63',
          distance: 100,
          enable: true,
          opacity: 1,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 2,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 800
          },
          value: 50
        }
      }
    }"
  />
  <Header />
  <Intro />
  <About />
  <Work />
  <Contact />
  <Footer />
</template>

<style lang="scss">
  @import '@/sass/main.scss';
  $aos-distance: 72px !default;
  @import 'node_modules/aos/src/sass/aos.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  #tsparticles {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
  }
</style>

<script>
import Header from './components/Header.vue'
import Intro from './components/Intro.vue'
import About from './components/About.vue'
import Work from './components/Work.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'
import AOS from 'aos';

export default {
  name: 'App',
  data() {
    return {
      isLoading: true
    };
  },
  components: {
    // Loader,
    Header,
    Intro,
    About,
    Work,
    Contact,
    Footer
  },
  mounted() {
    AOS.init({
      once: true
    });

    var title = document.title;
    var blurMessage = [
      "Please come back! :-( ", 
      "Don't you love me anymore? :-(", 
      "Fancy a cookie? ", 
      "I'm feeling lonely :-( "
    ];

    var intervalTimer = null;
    var timeoutTimer = null;

    window.addEventListener("blur", function () { 
       intervalTimer = setInterval(function() {
         var rand = Math.floor((Math.random() * blurMessage.length));
         document.title = blurMessage[rand];
       },5000);
    });

    window.addEventListener("focus", function(){ 
          clearInterval(intervalTimer);
          clearTimeout(timeoutTimer);
          document.title = title; 
    });
  }
}
</script>
