<template>
  <header role="banner" :class="{ navUp: hasNavUp, navDown: hasNavDown }">
    <div class="logo" data-aos="fade-down" data-aos-anchor="header"><a href="" aria-label="home">
      <svg width="100%" height="100%" viewBox="0 0 479 479" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path id="background" d="M11.219 238.536L238.189 9.107 467.72 239.52 239.934 471.107z" fill="#0A1148"/><g fill="#09C9BA"><path d="M468.495 229.495l-218.99-218.99c-5.53-5.53-14.483-5.527-20.01 0l-218.99 218.99c-5.53 5.53-5.527 14.483 0 20.01l218.99 218.99c5.53 5.53 14.483 5.527 20.01 0l218.99-218.99c5.53-5.53 5.527-14.483 0-20.01zM242.838 17.173l218.99 218.989a4.714 4.714 0 0 1 0 6.676l-218.99 218.99a4.714 4.714 0 0 1-6.676 0l-218.99-218.99a4.714 4.714 0 0 1 0-6.676l218.99-218.99a4.714 4.714 0 0 1 6.676 0z" fill-rule="nonzero"/><path d="M89.75 237.484a.747.747 0 0 1-.002-1.06L241.465 87.245a.77.77 0 0 1 1.083.007l52.732 51.85-7.036 6.918-46.241-45.465L96.788 243.338a.775.775 0 0 1-1.083.002l-5.955-5.856zm98.976 104.237l7.037-6.917 46.24 45.466L387.22 237.486a.775.775 0 0 1 1.083-.002l5.956 5.855a.747.747 0 0 1 .002 1.06L242.542 393.578a.77.77 0 0 1-1.083-.006l-52.733-51.85zm14.235-59.722c-.422 0-1.01-.242-1.3-.526l-8.88-8.733c-.296-.29-.201-.526.221-.526h96.328c.206 0 .493-.118.645-.267l14.656-14.411H168.12a.761.761 0 0 1-.756-.752v-8.282c0-.415.33-.751.755-.751h159.779c.417 0 .514.237.208.538L294.37 281.46c-.302.297-.88.538-1.312.538H202.96zm-34.841-48.926a.76.76 0 0 1-.755-.751v-8.282c0-.415.347-.751.755-.751h136.51l-14.655-14.41a1.07 1.07 0 0 0-.645-.268h-96.328c-.418 0-.51-.242-.221-.526l8.88-8.734c.296-.29.867-.526 1.3-.526h90.098c.422 0 1.006.237 1.312.538l33.736 33.172c.302.297.216.538-.208.538H168.12z"/></g></g></svg>
    </a></div>

    <nav>
      <ol>
        <li data-aos="fade-down" data-aos-anchor="header" data-aos-delay="150"><a @click.stop="scoller('about')" href="/#about">About</a></li>
        <li data-aos="fade-down" data-aos-anchor="header" data-aos-delay="200"><a @click.stop="scoller('work')" href="/#work">Work</a></li>
        <li data-aos="fade-down" data-aos-anchor="header" data-aos-delay="250"><a @click.stop="scoller('contact')" href="/#contact">Contact</a></li>
      </ol>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return {
      hasNavUp: false,
      hasNavDown: false,
      didScroll: false,
      lastScrollTop: 0,
      delta: 50,
      st: 0
    }
  },
  methods: {
    handleScroll () {
      this.didScroll = true
      this.st = window.top.scrollY

      if(this.st <= 0) this.hasNavDown = false;
      if(this.st <= 0 || Math.abs(this.lastScrollTop - this.st) <= this.delta) return;

      if (this.st > this.lastScrollTop) {
        this.hasNavUp = true
        this.hasNavDown = false
      } else {
        this.hasNavUp = false
        this.hasNavDown = true
      }

      this.lastScrollTop = this.st;
    },
    interval () {
      setInterval(() => {
        if (this.didScroll) {
          this.handleScroll();
          this.didScroll = false;
        }
      }, 250)
    },
    scoller (ele) {
      history.pushState("", document.title, window.location.pathname);

      var element = document.getElementById(ele);
      element.scrollIntoView({ 
        behavior: 'smooth' 
      });
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    this.interval();
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped lang="scss">
  header {
    z-index: 150;

    position: fixed;
    top: 0; left: 0; right: 0;

    padding: $spacer $margin;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: transparent;

    transition: transform $transition, background-color $transition, padding $transition;

    @include below($laptop) {
      padding: $spacer;
    }

    @include below($phone) {
      padding: $padding;
    }

    &.navUp {
      transform: translateY(-100%);
    }

    &.navDown {
      padding: 8*$rpx $margin;

      background-color: $secundo;

      @include below($laptop) {
        padding: 8*$rpx $spacer;
      }

      @include below($phone) {
        padding: 8*$rpx $padding;
      }
    }
  }

  .logo {
    width: 64*$rpx;
    height: 64*$rpx;

    flex: 0 0 64*$rpx;

    a {
      width: 100%;
      height: 100%;

      display: block;

      #background {
        transition: $transition;
      }

      &:hover #background {
        fill: $tertio;
      }
    }
  }

  nav {
    ol {
      display: flex;
      align-items: center;

      li {
        margin-left: $spacer;
      }

      a {
        color: $light;

        font-size: 14*$rpx;
        font-weight: 500;

        &:hover {
          color: $primo;
        }
      }
    }
  }
</style>