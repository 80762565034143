<template>
    <section class="page" id="contact">
        <div class="container" data-aos="fade-down">
            <h2>Contact me</h2>
            <p>Contact me or send me an email at <a href="mailto:hello@sermonbob.be">hello@sermonbob.be</a></p>
            <p v-if="!hide">Thank you for contacting me, I'll answer your message as soon as possible.</p>
            <form id="contact-form" @submit.prevent="onSubmit" novalidate  v-if="hide">
                <div class="input">
                  <input type="text" name="name" placeholder="Name" required v-model="name" />
                  <span v-if="v$.name.$error">
                    {{ v$.name.$errors[0].$message }}
                  </span>
                </div>
                <div class="input">
                    <input type="email" name="email" placeholder="E-mail" required v-model="email" />
                    <span v-if="v$.email.$error">
                      {{ v$.email.$errors[0].$message }}
                    </span>
                </div>
                <div class="input">
                  <textarea placeholder="Message" name="message" required v-model="message"></textarea>
                  <span v-if="v$.message.$error">
                    {{ v$.message.$errors[0].$message }}
                  </span>
                </div>
                <div class="input">
                    <button>Send me a message!</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)){
    return true;
  }
  return false;
}

export default {
  name: 'Contact',
  data() {
    return {
        v$: useVuelidate(),
        name: '',
        email: '',
        message: '',
        hide: true
      }
  },
  validations() {
    return {
      name: {
        required, name_validation: {
          $validator: validName,
          $message: 'Invalid name. Valid name only contain letters, dashes (-) and spaces'
        }
      },
      email: { required, email },
      message: { required }
    }
  },
  methods: {
    onSubmit() {
      this.v$.$validate()
      if (!this.v$.$error) {
        try {
          emailjs.send('smtpserver', 'contact_template', this.$data, 'user_WFawueDu5LUumHZvKAepz')
          this.hide = false
        } catch(error) {
          console.log({error})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
    .page {
      min-height: calc(100vh - #{128*$rpx});
    }

    form {
      margin-top: $gutter;
    }

    input,
    textarea,
    button {
      width: 100%;
      max-width: 616*$rpx;
      margin: 0;
      padding: 12*$rpx $padding;

      outline: 0;
      border: 1*$rpx solid $tertio;
      color: $light;
      background-color: transparent;
      border-radius: 8*$rpx;

      font: inherit;
      appearance: none;
      transition: $transition;

      &::placeholder {
        color: rgba($light, 0.32);
      }

      &:focus {
        border-color: $light;
      }
    }

    textarea {
      min-height: 128*$rpx;

      resize: vertical;
    }

    button {
      width: auto;
      padding: 11*$rpx $spacer;

      background-color: $primo;
      border: 2*$rpx solid $primo;
      border-radius: 24*$rpx;
      color: $secundo;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: $primo;
      }
    }

    .input {
      + .input {
        margin-top: 24*$rpx;
      }

      span {
        margin-top: 8*$rpx;

        display: block;

        color: $error;

        font-size: 12*$rpx;
        font-weight: 500;
      }
    }
</style>