<template>
    <section class="page" id="intro">
        <div class="container">
            <p data-aos="fade-up" data-aos-anchor="header" data-aos-delay="350" data-aos-duration="250">Hi, I’m</p>
            <h1 data-aos="fade-up" data-aos-anchor="header" data-aos-delay="400" data-aos-duration="250">Bob Sermon</h1>
            <h1 data-aos="fade-up" data-aos-anchor="header" data-aos-delay="450" data-aos-duration="250"><div class="console-text-container"><span id="console-text">I make things for the web.</span></div></h1>
            <p data-aos="fade-up" data-aos-anchor="header" data-aos-delay="500" data-aos-duration="250">I’m a front-end developer based in Belgium who specializes in building digital experiences.</p>
            <p data-aos="fade-up" data-aos-anchor="header" data-aos-delay="550" data-aos-duration="250">View my <a @click.stop="scoller('work')" href="#work">Work</a>, <a @click.stop="scoller('contact')" href="#contact">Contact</a> me or send me an email at <a href="mailto:hello@sermonbob.be">hello@sermonbob.be</a></p>
            <a @click.stop="scoller('about')" href="/#about" class="button" data-aos="fade-up" data-aos-anchor="header" data-aos-delay="600" data-aos-duration="250">Discover more about me</a>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Intro',
  mounted() {
    this.consoleText(['I make things for the web.', 'I am a passionate landscape photographer.', 'I play board games.'], 'console-text');
  },
  methods: {
    scoller (element) {
      document.querySelector('#'+element).scrollIntoView({ behavior: 'smooth' });
    },
    consoleText(words, id, colors) {
      if (colors === undefined) colors = ['#09C9BA'];
      var letterCount = 1;
      var x = 1;
      var waiting = false;
      var target = document.getElementById(id)
      target.setAttribute('style', 'color:' + colors[0])
      window.setInterval(function() {
        if (letterCount === 0 && waiting === false) {
          waiting = true;
          target.innerHTML = words[0].substring(0, letterCount)
          window.setTimeout(function() {
            var usedColor = colors.shift();
            colors.push(usedColor);
            var usedWord = words.shift();
            words.push(usedWord);
            x = 1;
            target.setAttribute('style', 'color:' + colors[0])
            letterCount += x;
            waiting = false;
          }, 1000)
        } else if (letterCount === words[0].length + 1 && waiting === false) {
          waiting = true;
          window.setTimeout(function() {
            x = -1;
            letterCount += x;
            waiting = false;
          }, 1000)
        } else if (waiting === false) {
          target.innerHTML = words[0].substring(0, letterCount)
          letterCount += x;
        }
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
    .page {
        min-height: 100vh;

        h1 {
            margin-top: $padding;
            margin-left: -3*$rpx;

            + p {
                margin-top: $spacer;
            }
        }

        .console-text-container {
          min-height: 64*$rpx;

          @include below($phablet) {
              min-height: 48*$rpx;
          }

          @include below($phone) {
              min-height: 40*$rpx;
          }
        }

        p {
            font-size: 20*$rpx;
            line-height: 1.4;
            font-weight: 500;

            @include below($phone) {
              font-size: 16*$rpx;
            }

            + p {
                margin-top: $spacer;
            }
        }

        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateX(0);
          }
          40% {
            transform: translateX(30px);
          }
          60% {
            transform: translateX(15px);
          }
        }

        .button {
            padding-right: 32*$rpx;
            margin-top: $gutter;

            display: inline-block;

            font-weight: normal;

            &:after {
                content: '';

                position: absolute;
                top: 50%; right: 0;

                width: 23*$rpx;
                height: 11*$rpx;
                margin-top: -5*$rpx;

                background: url("data:image/svg+xml,%3Csvg width='23' height='11' viewBox='0 0 23 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.887 4.613h18.787l-3.112-3.097A.887.887 0 1 1 17.813.258l4.636 4.613v.001a.888.888 0 0 1 0 1.256l-4.635 4.614a.887.887 0 0 1-1.252-1.258l3.112-3.097H.887a.887.887 0 1 1 0-1.774z' fill='%2309C9BA' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat right center;

                animation: bounce 2s infinite;
            }

            &:hover {
                color: lighten($primo, 10%);
            }
        }
    }
</style>