<template>
  <footer>
    <div class="logo">
      <svg width="100%" height="100%" viewBox="0 0 479 479" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path id="background" d="M11.219 238.536L238.189 9.107 467.72 239.52 239.934 471.107z" fill="#0A1148"/><g fill="#09C9BA"><path d="M468.495 229.495l-218.99-218.99c-5.53-5.53-14.483-5.527-20.01 0l-218.99 218.99c-5.53 5.53-5.527 14.483 0 20.01l218.99 218.99c5.53 5.53 14.483 5.527 20.01 0l218.99-218.99c5.53-5.53 5.527-14.483 0-20.01zM242.838 17.173l218.99 218.989a4.714 4.714 0 0 1 0 6.676l-218.99 218.99a4.714 4.714 0 0 1-6.676 0l-218.99-218.99a4.714 4.714 0 0 1 0-6.676l218.99-218.99a4.714 4.714 0 0 1 6.676 0z" fill-rule="nonzero"/><path d="M89.75 237.484a.747.747 0 0 1-.002-1.06L241.465 87.245a.77.77 0 0 1 1.083.007l52.732 51.85-7.036 6.918-46.241-45.465L96.788 243.338a.775.775 0 0 1-1.083.002l-5.955-5.856zm98.976 104.237l7.037-6.917 46.24 45.466L387.22 237.486a.775.775 0 0 1 1.083-.002l5.956 5.855a.747.747 0 0 1 .002 1.06L242.542 393.578a.77.77 0 0 1-1.083-.006l-52.733-51.85zm14.235-59.722c-.422 0-1.01-.242-1.3-.526l-8.88-8.733c-.296-.29-.201-.526.221-.526h96.328c.206 0 .493-.118.645-.267l14.656-14.411H168.12a.761.761 0 0 1-.756-.752v-8.282c0-.415.33-.751.755-.751h159.779c.417 0 .514.237.208.538L294.37 281.46c-.302.297-.88.538-1.312.538H202.96zm-34.841-48.926a.76.76 0 0 1-.755-.751v-8.282c0-.415.347-.751.755-.751h136.51l-14.655-14.41a1.07 1.07 0 0 0-.645-.268h-96.328c-.418 0-.51-.242-.221-.526l8.88-8.734c.296-.29.867-.526 1.3-.526h90.098c.422 0 1.006.237 1.312.538l33.736 33.172c.302.297.216.538-.208.538H168.12z"/></g></g></svg>
    </div>
    <p>&copy; Copyright {{ new Date().getFullYear() }}. All right reserved</p>
    <ul class="social-links">
      <li><a href="https://www.facebook.com/sermonbobfrontenddeveloper/" class="icon icon-facebook"></a></li>
      <li><a href="https://www.linkedin.com/in/bobsermon/" class="icon icon-linkedin"></a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
  footer {
    padding: $spacer $margin;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include below($laptop) {
      padding: $spacer;
    }

    @include below($phone) {
      padding: $padding;
    }
  }

  .logo {
    width: 64*$rpx;
    height: 64*$rpx;
  }

  p {
    font-size: 14*$rpx;

    @include below($phone) {
      font-size: 12*$rpx;
    }
  }

  .social-links {
    display: flex;
    align-items: center;

    li {
      + li {
        margin-left: 24*$rpx;

        @include below($phone) {
          margin-left: 12*$rpx;
        }
      }
    }
  }

  .icon {
    display: inline-block;

    &.icon-facebook {
      width: 25*$rpx;
      height: 24*$rpx;

      background: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.42 24H1.924A1.325 1.325 0 0 1 .6 22.675V1.325C.6.593 1.193 0 1.925 0h21.35c.732 0 1.325.593 1.325 1.325v21.35c0 .732-.593 1.325-1.325 1.325H17.16v-9.294h3.12l.466-3.622H17.16V8.77c0-1.048.29-1.763 1.795-1.763h1.918v-3.24c-.332-.045-1.47-.143-2.795-.143-2.766 0-4.659 1.688-4.659 4.788v2.67h-3.127v3.623h3.127V24z' fill='%23DBE5EF' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    &.icon-linkedin {
      width: 27*$rpx;
      height: 24*$rpx;

      background: url("data:image/svg+xml,%3Csvg width='27' height='24' viewBox='0 0 27 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27 24h-5.835v-8.437c0-2.208-.912-3.716-2.92-3.716-1.535 0-2.388 1.017-2.786 1.998-.149.352-.125.842-.125 1.332V24h-5.78s.074-14.945 0-16.304h5.78v2.559c.341-1.119 2.188-2.716 5.136-2.716 3.657 0 6.53 2.346 6.53 7.395V24zM3.708 5.657H3.67C1.808 5.657.6 4.41.6 2.83.6 1.22 1.843 0 3.743 0 5.642 0 6.81 1.217 6.846 2.826c0 1.58-1.204 2.83-3.138 2.83zm-2.442 2.04H6.41V24H1.266V7.696z' fill='%23DBE5EF' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
</style>