<template>
    <section class="page" id="about">
        <div class="container" data-aos="fade-down" data-aos-offset="200">
            <h2>About me</h2>
            <p>Hi there! 👋 I'm <strong>Bob</strong> and I am a front-end developer based in Belgium. I've been building websites since 2013 with a focus on responsive design, accessibility and pleasing aesthetics. <br />A few technologies I've been working with: PHP, HTML, Sass and Vue.</p>

            <p>Being a diligent, hardworking and result oriented person, I always work towards achieving best result on each project I lay my hands on.</p>

            <p>Away from the keyboard in my "spare" time I could be doing any number of things. I am either playing board game, video game, or watching a movie. I'm also very passionate about landscape photography.</p>

            <div class="me">
                <img :src="transformImgExt('images/me.png')" :srcset="transformImgExt('images/me.png')+ ' 1x, '+transformImgExt('images/me@2x.png')+ ' 2x'" width="364" alt="Bob Sermon | Front-end developer">
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Intro',
  data() {
    return {
        webpSupported: true
    }
  },
  methods: {
      transformImgExt (url) {
      if (this.webpSupported) {
        return url.replace(/\.\w{1,5}$/, ".webp");
      } else { // Otherwise, just return the original
        return url;
      }
    }
  },
  created() {
    (async () => {
        // If browser doesn't have createImageBitmap, we can't use webp.
        if (!self.createImageBitmap) {
        this.webpSupported = false;
        return;
        }

        // Base64 representation of a white point image
        const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
        // Retrieve the Image in Blob Format
        const blob = await fetch(webpData).then(r => r.blob());
        // If the createImageBitmap method succeeds, return true, otherwise false
        this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

    })();
  }
}
</script>

<style scoped lang="scss">
    .page {
        p {
            max-width: 616*$rpx;

            @include below($netbook) {
                max-width: 474*$rpx;
            }

            @include below($tablet) {
                max-width: 100%;;
            }
        }

        .me {
            position: absolute;
            right: -220*$rpx; top: -$gutter;

            width: 364*$rpx;

            @include below($width) {
                right: 0;

                width: 200*$rpx;
            }

            @include below($laptop) {
                right: $spacer;
            }

            @include below($tablet) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }
</style>