<template>
    <section class="page" id="work">
        <div class="container" data-aos="fade-down">
            <h2>Things I’ve build</h2>
            <div class="featured-projects">
                <article class="featured-project" data-aos="fade-left">
                    <div class="project-content">
                        <div class="title">Bloovi</div>
                        <ul class="snippets">
                            <li><span>html</span></li>
                            <li><span>css</span></li>
                        </ul>
                        <p class="content">Bloovi is a content platform that brings inspiring content daily in a storytelling way. Each story offers a Blooming Vision of the world of tomorrow.</p>
                    </div>
                    <div class="project-image">
                        <figure>
                            <img :src="transformImgExt('images/bloovi.jpg')" :srcset="transformImgExt('images/bloovi.jpg')+ ' 1x, '+transformImgExt('images/bloovi@2x.jpg')+ ' 2x'" width="515" alt="Bob Sermon | Front-end developer - Bloovi" />
                        </figure>
                    </div>
                </article>
                <article class="featured-project" data-aos="fade-right">
                    <div class="project-content">
                        <div class="title">Jacosy</div>
                        <ul class="snippets">
                            <li><span>php</span></li>
                            <li><span>css</span></li>
                        </ul>
                        <p class="content">Jacosy guarantees you wonderful wellness moments - alone or with your bubble. Surrender to the moment and enjoy the unique experience of your new jacuzzi.</p>
                    </div>
                    <div class="project-image">
                        <figure>
                            <img :src="transformImgExt('images/jacosy.jpg')" :srcset="transformImgExt('images/jacosy.jpg')+ ' 1x, '+transformImgExt('images/jacosy@2x.jpg')+ ' 2x'" width="515" alt="Bob Sermon | Front-end developer - Jacosy" />
                        </figure>
                    </div>
                </article>
                <article class="featured-project" data-aos="fade-left">
                    <div class="project-content">
                        <div class="title">The Harbour</div>
                        <ul class="snippets">
                            <li><span>html</span></li>
                            <li><span>css</span></li>
                        </ul>
                        <p class="content">CRM platform for The Harbour for all there KMO customers</p>
                    </div>
                    <div class="project-image">
                        <figure>
                            <img :src="transformImgExt('images/harbour.jpg')" :srcset="transformImgExt('images/harbour.jpg')+ ' 1x, '+transformImgExt('images/harbour@2x.jpg')+ ' 2x'" width="515" alt="Bob Sermon | Front-end developer - The harbour" />
                        </figure>
                    </div>
                </article>
            </div>

            <div class="projects">
                <article class="project" data-aos="fade-up">
                    <div class="project-content">
                        <div class="title">PJV</div>
                        <ul class="snippets">
                            <li><span>html</span></li>
                            <li><span>css</span></li>
                        </ul>
                        <p class="content">Christian youth association aimed at youth from 0 to 30 years old from Pentecostal churches in Flanders and Brussels</p>
                    </div>
                </article>
                <article class="project" data-aos="fade-up">
                    <div class="project-content">
                        <div class="title">Hoffelijk</div>
                        <ul class="snippets">
                            <li><span>css</span></li>
                            <li><span>php</span></li>
                        </ul>
                        <p class="content">Hoffelijk BV consists of 3 ambitious guys, thanks to their years of experience they are the experts for the construction and maintenance of all your garden projects</p>
                    </div>
                </article>
                <article class="project" data-aos="fade-up">
                    <div class="project-content">
                        <div class="title">Yucopia</div>
                        <ul class="snippets">
                            <li><span>css</span></li>
                            <li><span>javascript</span></li>
                        </ul>
                        <p class="content">The platform with which you, as an SME, self-employed person or liberal profession, digitally manage all your purchases.</p>
                    </div>
                </article>
                <article class="project" data-aos="fade-up">
                    <div class="project-content">
                        <div class="title">Exile</div>
                        <ul class="snippets">
                            <li><span>html</span></li>
                            <li><span>css</span></li>
                        </ul>
                        <p class="content">Exile is a platform for hairdressers to manage your business and appointments and prevent no-shows.</p>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
      name: 'Work',
      data() {
        return {
            webpSupported: true
        }
    },
    methods: {
        transformImgExt (url) {
        if (this.webpSupported) {
            return url.replace(/\.\w{1,5}$/, ".webp");
        } else { // Otherwise, just return the original
            return url;
        }
        }
    },
    created() {
        (async () => {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
            this.webpSupported = false;
            return;
            }

            // Base64 representation of a white point image
            const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then(r => r.blob());
            // If the createImageBitmap method succeeds, return true, otherwise false
            this.webpSupported = await createImageBitmap(blob).then(() => true, () => false);

        })();
    }
    }
</script>

<style scoped lang="scss">
    .page {
        margin-top: 216*$rpx;

        @include below($phone) {
            margin-top: $margin;
        }
    }

    .featured-projects {
        .featured-project {
            display: flex;

            @include below($phablet) {
                flex-wrap: wrap;
            }

            .project-content {
                z-index: 10;

                flex: 1 1 0*$rpx;
                margin-bottom: $gutter;

                align-self: flex-end;

                @include below($phablet) {
                    position: absolute; 
                    top: 0; left: 0; right: 0; bottom: 0;

                    padding: 24*$rpx;
                    margin-bottom: 0;

                    display: flex; 
                    flex-direction: column;
                    justify-content: center;
                
                    background: rgba($secundo, 0.8);
                }

                .content {
                    @include above($phablet) {
                        padding: 24*$rpx;
    
                        background-color: $tertio;
                        border-radius: 8*$rpx;
                        box-shadow: 0 0 16*$rpx $secundo;
                        
                    }
                }
            }

            .project-image {
                z-index: 5;

                flex-basis: 515*$rpx;
                margin-left: -$size;
            
                @include below($tablet) {
                    flex-basis: 374*$rpx;
                }

                @include below($phablet) {
                    flex-basis: 100%;
                    margin-left: 0;
                }

                figure {
                    border-radius: 8*$rpx;
                    
                    overflow: hidden;

                    @include below($netbook) {
                        min-height: 314*$rpx;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: cover;

                    @include below($netbook) {
                        position: absolute;
                        top: 0; left: 50%;

                        transform: translateX(-50%);
                    }
                }
            }

            + .featured-project {
                margin-top: $gutter;
            }

            &:nth-child(even) {
                @include above($phablet) {
                    .project-content {
                        order: 2;

                        text-align: right;

                        .snippets {
                            justify-content: flex-end;
                        }
                    }

                    .project-image {
                        order: 1;

                        margin-left: 0;
                        margin-right: -$size;

                        @include below($phablet) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .projects {
        margin-top: $margin;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: $spacer;

        @include below($phablet) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
        }

        .project {
            .project-content {
                padding: 24*$rpx;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                background-color: $tertio;
                border-radius: 8*$rpx;

                @include below($phablet) {
                    padding-top: 24*$rpx;
                }
            }
        }
    }

    .title {
        margin-bottom: 8*$rpx;

        font-size: 24*$rpx;
        font-weight: 900;
    }

    .snippets {
        margin-bottom: 24*$rpx;

        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 4*$rpx;
        }

        span {
            padding: 0 8*$rpx;

            display: block;

            background: $primo;
            color: $secundo;
            border-radius: 12*$rpx;

            font-size: 12*$rpx;
        }
    }

    .content {
        font-size: 14*$rpx;
        line-height: 1.57142857;
    }
</style>